.modal-paper {
  width: 20%;
  min-width: 350px;
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 20px;
}
.modal-header button {
  position: fixed;
  top: 18px;
  right: 18px;
  border-radius: 20px;
}

.modal-header h2 {
  text-align: center;
}

form.change-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-body {
  margin-bottom: 20px;
}

span.MuiButton-label {
  display: inline-flex;
}

.ok {
  display: block;
  font-size: 22px;
  color: green;
  font-weight: bold;
  margin: 15px 0;
  text-align: center;
}

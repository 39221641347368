.table-input {
  border: none;
  outline: none;
  outline-style: none;
  outline-width: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  border-bottom-style: none;
  min-width: unset;
  width: 100%;
  height: 21px;
}

.table-input:focus {
  outline: none;
}

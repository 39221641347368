.user-list {
  padding: 0 20px;
}

.list-search-container {
  margin: 0 5%;
}

.data-grid-container {
  margin: auto;
  padding: 2% 0 2% 0;
}

.Mui-even.MuiDataGrid-row {
  cursor: pointer;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

div.container-modal
  > div.chakra-portal
  > div
  > div.chakra-modal__content-container {
  position: relative;
  width: 100%;
  height: 100%;
}

div.container-modal > div.chakra-portal,
div.container-modal > div.chakra-portal > div {
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
}

.echo-checkbox {
  cursor: pointer;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  vertical-align: top;
  position: relative;
  min-height: 24px;
}

.datagrid-save-icon {
  animation: bounce 1.5s ease infinite;
}

@keyframes bounce {
  70% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(-20%);
  }
  90% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(-10%);
  }
  97% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(-5%);
  }
  100% {
    transform: translateY(0);
  }
}

/* form.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 100vh;
} */

.MuiFormControl-root {
  width: 300px;
  margin-bottom: 10px;
}

span.forgetPassword {
  margin-bottom: 20px;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

@import "react-quill/dist/quill.snow.css";

.ql-editor.ql-blank::before {
  color: #ccc;
  content: attr(data-placeholder);
  font-style: italic;
  pointer-events: none;
  position: absolute;
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

.ql-editor * {
  all: revert;
  -webkit-user-select: text;
  user-select: text;
}

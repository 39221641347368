.panel-component-container {
  width: 384px;
  z-index: 10;
  position: relative;
  transition: width 0.5s ease;
}

.panel-component-hidden-container {
  width: 24px;
}

.panel-component-left {
  display: flex;
  flex-direction: row-reverse;
}

.panel-component-right {
  display: flex;
  flex-direction: row;
}

.button-labels-container {
  display: flex;
  flex-direction: column;
}

.collapse-button {
  width: 24px;
  overflow: hidden;
}

.rotation-wrapper-outer {
  display: table;
  width: 24px;
}
.rotation-wrapper-inner {
  padding: 50% 0;
  height: 0;
}

.button-content-rotate-left {
  display: block;
  transform-origin: top left;
  transform: rotate(-90deg) translate(-100%);
  margin-top: -50%;
  white-space: nowrap;
}

.button-content-rotate-right {
  display: block;
  transform-origin: top left;
  /* width: 24px; */
  transform: rotate(90deg) translate(0%, -100%);
  margin-top: -50%;
  white-space: nowrap;
}

.panel-component-content {
  width: 100%;
  /* background-color: #e1eff3; */
}

.panel-component-content-shadow {
  -webkit-box-shadow: 0px 0px 19px 5px rgba(88, 116, 138, 0.47);
  box-shadow: 0px 0px 19px 5px rgba(88, 116, 138, 0.47);
}

.hide-panel-component * {
  display: none !important;
}

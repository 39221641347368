.table-select {
  border: none;
  outline: none;
  background: none;
  outline-style: none;
  outline-width: 0;
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  border-bottom-style: none;
  min-width: unset;
  width: 100%;
  height: 21px;
}

.table-select:focus {
  outline: none;
}
